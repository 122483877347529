'use client'

import { useEffect, useRef, useState } from 'react'
import { usePathname, useSearchParams } from 'next/navigation'
import {
  clientTrackPageView,
  identifyUser,
  LEAD_ID_KEY,
  track,
  trackConsentEvent,
} from '@/lib/hightouch/events'
import { fetchCookie, saveCookie } from '@/utils/cookie-storage'
import { isRouteBlocked } from '@/utils/route-blocklist'
import * as Sentry from '@sentry/nextjs'
import { KetchConsent, canTrack, getKetchConsent } from '@/lib/ketch'

interface URLComponents {
  pathname: string
  search: string
  hash: string
}

const CONSENT_HASH_KEY = 'ketch_consent_hash'

export interface KetchConsentData {
  purposes: KetchConsent
}

export interface KetchObject {
  (
    // eslint-disable-next-line no-unused-vars
    command: string,
    // eslint-disable-next-line no-unused-vars
    event: string,
    // eslint-disable-next-line no-unused-vars
    callback: (data: KetchConsentData) => void,
  ): void
  // eslint-disable-next-line no-unused-vars
  (command: 'getConsent', callback: (data: KetchConsentData) => void): void
  // eslint-disable-next-line no-unused-vars
  on(event: string, callback: (data: KetchConsentData) => void): void
}

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    ketch: KetchObject
  }
}

const generateConsentHash = (consent: KetchConsent): string =>
  btoa(JSON.stringify(consent)).replace(/=/g, '')

const saveConsentHashToStorage = (hash: string) => {
  saveCookie(hash, CONSENT_HASH_KEY)
}

const getConsentHashFromStorage = (): string | null => {
  return fetchCookie<string>(CONSENT_HASH_KEY)
}

export function HightouchController() {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const storedLeadId = fetchCookie<string>(LEAD_ID_KEY)
  const [leadId, setLeadId] = useState<string | null>(storedLeadId)
  const hasUserInitialized = useRef(false)
  const [previousURL, setPreviousURL] = useState<URLComponents>({
    pathname: '',
    search: '',
    hash: '',
  })
  const [consentState, setConsentState] = useState<KetchConsent>({
    analytics: false,
    personalization: false,
    essential_services: true,
    product_enhancement: false,
  })
  const [hasPageEventFired, setHasPageEventFired] = useState(false)

  const initializeUser = async () => {
    if (storedLeadId || hasUserInitialized.current || !canTrack(consentState)) {
      return
    }

    try {
      const newLeadId = await identifyUser()
      saveCookie(newLeadId, LEAD_ID_KEY)
      setLeadId(newLeadId)
      hasUserInitialized.current = true
    } catch (err) {
      Sentry.captureException(err)
    }
  }

  const getCurrentURL = (): URLComponents => ({
    pathname: pathname ?? '',
    search: searchParams.toString(),
    hash: window.location.hash,
  })

  const hasPathChanged = (currentURL: URLComponents, prevURL: URLComponents) =>
    currentURL.pathname !== prevURL.pathname
  const hasHashChanged = (currentURL: URLComponents, prevURL: URLComponents) =>
    currentURL.hash !== prevURL.hash

  const trackPageView = async (pathname: string, hash: string | null) => {
    if (leadId) {
      await clientTrackPageView(pathname, leadId, hash)
      setHasPageEventFired(true)
    }
  }

  const trackInternalNavigation = (
    pathname: string,
    search: string,
    hash: string,
  ) => {
    const url = `${pathname}${search}${hash}`
    track('Internal Navigation', { url })
  }

  const handleConsentUpdate = async (consentData: {
    purposes: KetchConsent
  }) => {
    const consentResponse = consentData.purposes
    const newConsentHash = generateConsentHash(consentResponse)
    const prevConsentHash = getConsentHashFromStorage()
    setConsentState(consentResponse)

    //compare against old consent hash to prevent duplicating consent events
    if (newConsentHash !== prevConsentHash && leadId) {
      saveConsentHashToStorage(newConsentHash)
      trackConsentEvent(leadId)
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && window.ketch) {
      window.ketch(
        'on',
        'consent',
        (consentData: { purposes: KetchConsent }) => {
          handleConsentUpdate(consentData)
        },
      )
      getKetchConsent().then((consent) =>
        handleConsentUpdate({ purposes: consent }),
      )
    }
  }, [leadId])

  useEffect(() => {
    initializeUser()
  }, [consentState, storedLeadId])

  useEffect(() => {
    const trackNavigation = async (prevUrl: URLComponents) => {
      if (
        typeof window === 'undefined' ||
        !leadId ||
        isRouteBlocked(pathname ?? '') ||
        !canTrack(consentState)
      ) {
        return
      }

      try {
        const currentURL = getCurrentURL()
        const pageChanged = hasPathChanged(currentURL, prevUrl)
        const hashChanged = hasHashChanged(currentURL, prevUrl)

        if (pageChanged || !hasPageEventFired) {
          await trackPageView(currentURL.pathname, currentURL.hash ?? '')
        } else if (hashChanged) {
          trackInternalNavigation(
            currentURL.pathname,
            currentURL.search,
            currentURL.hash,
          )
        }
      } catch (err: unknown) {
        Sentry.captureException(err)
      }
    }

    const currentURL = getCurrentURL()
    const localPrevUrl = previousURL
    setPreviousURL(currentURL)
    trackNavigation(localPrevUrl)
  }, [pathname, searchParams, consentState, leadId, hasPageEventFired])

  return null
}
